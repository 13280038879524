
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { defineComponent, onMounted, ref } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { SmsActions } from "@/store/vm/sms/enums";
import Keywords from "@/views/sms/child/Keywords.vue";
import Detector from "@/views/sms/child/Detector.vue";

export default defineComponent({
  name: "IndexSMS",
  props: {
    widgetClasses: String,
  },
  components: {
    Keywords,
    Detector,
  },
  setup() {
    const store = useStore();
    const hasGotData = ref(false);
    const signal = ref<string[]>([]);
    const undetectedChannel = ref<string>("");
    const detector = ref({});
    const detectorComponent = ref();

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Sms listening", ["Sms"]);
      MenuComponent.reinitialization();

      const response = await store
        .dispatch(SmsActions.GET_CONFIG)
        .catch((e) => {
          console.log(e);
        });
      if (response.data.status === 1000) {
        const data = response.data.data;
        signal.value = data.signal;
        detector.value = data.detector;
        undetectedChannel.value = data.channelUndetected;

        hasGotData.value = true;
      } else {
        Swal.fire({
          title:
            "Đã xảy ra lỗi trong quá trình kết nối đến server SMS Listening",
          text: "",
          icon: "warning",
        });
      }
    });

    const onChange = (par) => {
      if (par.name === "signal") {
        signal.value = par.value;
      }
    };

    const save = async () => {
      const detectorData = detectorComponent.value.get();
      const config = {
        detector: detectorData,
        channelUndetected: undetectedChannel.value,
        signal: signal.value,
      };

      const response = await store
        .dispatch(SmsActions.SET_CONFIG, config)
        .catch((e) => {
          console.log(e);
        });

      if (response.data.status === 1000) {
        Swal.fire({
          title: "Cập nhật thành công",
          text: "",
          icon: "success",
        });
      } else {
        Swal.fire({
          title:
            "Đã xảy ra lỗi trong quá trình kết nối đến server SMS Listening",
          text: "",
          icon: "warning",
        });
      }
    };

    return {
      onChange,
      signal,
      detector,
      undetectedChannel,
      hasGotData,
      detectorComponent,
      save,
    };
  },
});
