
import { defineComponent, onMounted, ref } from "vue";
import { generateRandomString } from "@/core/helpers/documentation";
import Keywords from "@/views/sms/child/Keywords.vue";

interface Detector {
  id: string;
  channelName: string;
  value: string[];
}

export default defineComponent({
  name: "detector",
  emits: ["change"],
  components: {
    Keywords,
  },
  props: {
    detector: {
      type: Object,
    },
    name: {
      type: String,
      require: true,
    },
  },
  setup(props) {
    const list = ref<Detector[]>([]);

    onMounted(async () => {
      const detectors: Detector[] = [];
      for (const channelName in props.detector) {
        const element = props.detector[channelName];
        detectors.push({
          id: generateRandomString(7),
          channelName: channelName,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          value: element as any,
        });
      }
      list.value = detectors;
    });

    const deleteRule = (val) => {
      list.value = list.value.filter((m) => m.id !== val);
    };

    const addRule = () => {
      list.value.push({
        id: generateRandomString(7),
        channelName: "channel-slack-" + generateRandomString(7),
        value: [""],
      });
    };

    const onChangeKeywords = (par) => {
      list.value.forEach((m) => {
        if (m["id"] === par["name"]) {
          m.value = par.value;
        }
      });
    };

    const get = () => {
      const formatData = {};
      list.value.forEach((m) => {
        formatData[m["channelName"]] = m["value"];
      });
      return formatData;
    };

    return {
      list,
      addRule,
      deleteRule,
      onChangeKeywords,
      get,
    };
  },
});
