
import { defineComponent, onMounted, ref } from "vue";
import { generateRandomString } from "@/core/helpers/documentation";

interface KeywordOptions {
  id: string;
  value: string;
}

export default defineComponent({
  name: "keyword-inputs",
  emits: ["change"],
  props: {
    keywords: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      require: true,
    },
  },
  setup(props, { emit }) {
    const isEditable = ref(false);
    const list = ref<KeywordOptions[]>([]);
    onMounted(async () => {
      const keywordArr: KeywordOptions[] = [];
      props.keywords.forEach((m) => {
        keywordArr.push({
          id: generateRandomString(7),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          value: m as any,
        });
      });
      list.value = keywordArr;
    });

    const del = (val) => {
      list.value = list.value.filter((m) => m.id !== val);
    };

    const add = () => {
      list.value.push({
        id: generateRandomString(7),
        value: "",
      });
    };

    const edit = () => {
      isEditable.value = true;
    };

    const save = () => {
      const value: string[] = [];
      list.value.map((m) => {
        value.push(m.value);
      });

      const uniqueValue = value.filter((v, i, a) => a.indexOf(v) === i);
      if (value.length > uniqueValue.length) {
        alert("Keyword bị trùng nhau! Vui lòng nhập các keyword khác biệt");
        return;
      }

      isEditable.value = false;
      emit("change", {
        name: props.name,
        value: uniqueValue,
      });
    };

    return {
      list,
      add,
      edit,
      del,
      save,
      isEditable,
    };
  },
});
