import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row g-9 mb-7" }
const _hoisted_2 = { class: "col-md-9 fv-row" }
const _hoisted_3 = { class: "col-md-3 fv-row" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_el_input, {
              modelValue: item.value,
              "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
              type: "text",
              disabled: !_ctx.isEditable,
              "input-style": "color: black"
            }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            (_ctx.isEditable)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "btn btn-danger btn-sm",
                  onClick: ($event: any) => (_ctx.del(item.id))
                }, " Xóa keyword ", 8, _hoisted_4))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
    }), 128)),
    _createElementVNode("div", null, [
      (_ctx.isEditable)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "btn btn-success btn-sm",
            style: {"margin-right":"8px"},
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.add()))
          }, " Thêm keyword "))
        : _createCommentVNode("", true),
      (!_ctx.isEditable)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "btn btn-warning btn-sm",
            style: {"margin-right":"8px"},
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.edit()))
          }, " Chỉnh sửa keyword "))
        : _createCommentVNode("", true),
      (_ctx.isEditable)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            class: "btn btn-warning btn-sm",
            style: {"margin-right":"8px"},
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.save()))
          }, " Ghi "))
        : _createCommentVNode("", true)
    ])
  ]))
}